import React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SliceZone,
} from '../components';
import {
  PostList,
} from '../components/blog';

const blogCategory = (props) => {
  const {
    data: {
      site: {
        siteMetadata,
      },
      page: {
        data: pageData,
      },
      blog,
      categories,
    },
    location,
    pageContext,
  } = props;
  const {
    blogSlug,
  } = siteMetadata;
  const {
    body,
    metaTitle,
    metaDescription,
    openGraphImage,
  } = pageData;
  const seoData = {
    metaTitle,
    metaDescription,
    openGraphImage,
  };
  const postItems = blog && blog.edges.map(post => post.node);
  const categoryItems = categories && categories.edges.map(category => category.node);
  const pathPrefix = `/${blogSlug}`;
  return (
    <Layout location={location} seoData={seoData}>
      <SliceZone
        allSlices={body}
        location={location}
      />
      <PostList
        posts={postItems}
        categories={categoryItems}
        siteMetadata={siteMetadata}
        location={location}
        pageContext={pageContext}
        pathPrefix={pathPrefix}
      />
    </Layout>
  );
};

export default blogCategory;

export const pageQuery = graphql`
  query BlogCategoryPage($categoryUid: String!, $limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        blogSlug,
        categorySlug,
      }
    }
    page: prismicBlogCategory(uid: { eq: $categoryUid }) {
      uid,
      data {
        metaTitle: meta_title {
          html
          text
        },
        metaDescription: meta_description {
          html
          text
        },
        openGraphImage: open_graph_image {
          alt
          copyright
          url
        }
        body {
          ... on PrismicBlogCategoryBodyPageHero {
            id
            sliceType: slice_type
            primary {
              headline {
                text
              }
              subtitle {
                text
              }
              illustrationStyle: illustration_style
              illustrator {
                document {
                  ... on PrismicArtist {
                    data {
                      name {
                        text
                      }
                    }
                  }
                }
              }
              description {
                text
              }
              illustration {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_withWebp
                }
                alt
              }
              theme
            }
          }
        }
      }
    }
    blog: allPrismicBlogPost(
      limit: $limit
      skip: $skip
      sort: { fields: [data___date], order: DESC }
      filter: {
        data: {
          category: {
            uid: { eq: $categoryUid }
          }
        }
      }
    ) {
      edges {
        node {
          uid
          data {
          postTitle: post_title {
            text
          }
          featuredImage: featured_image {
            fluid(maxWidth: 1200) {
              ...GatsbyPrismicImageFluid_withWebp
            }
            alt
          }
          featuredImageStyle: feature_image_style
          openingText: opening_text {
            html
          }
          date(formatString: "YYYY-MM-DD")
          category {
            document {
              ... on PrismicBlogCategory {
                uid
                data {
                  categoryName: category_name {
                    text
                  }
                }
              }
            }
          }
          author {
            document {
              ... on PrismicBlogAuthor {
                data {
                  authorName: author_name {
                    text
                  }
                }
              }
            }
          }
        }
        }
      }
    }
    categories: allPrismicBlogCategory {
      edges {
        node {
          uid
          data {
            categoryName: category_name {
              text
            }
          }
        }
      }
    }
  }
`;
